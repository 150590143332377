import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { func } from 'prop-types';
import { bindActionCreators } from 'redux';
import actions from 'actions';
import { connect } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery } from '@icapitalnetwork/supernova-hooks';
import Snackbar from '@icapitalnetwork/supernova-core/Snackbar';
import Button from '@icapitalnetwork/supernova-core/Button';
import IconButton from '@icapitalnetwork/supernova-core/IconButton';
import styles from './PassportSnackbar.module.scss';

function PassportSnackbar({ togglePassportMenu }) {
  const { t } = useTranslation(['passport']);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const style = isSmall ? { bottom: 'unset', padding: '0 15px' } : {};

  const [open, setOpen] = useState(true);

  const handleClose = () => setOpen(false);

  const handleActionClick = () => {
    togglePassportMenu();
    setOpen(false);
  };

  const action = (
    <>
      <Button variant="text" size="small" onClick={handleActionClick}>
        {t('passport:switch_account_other', 'Switch accounts')}
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <div className={styles.passport_snackbar}>
      <Snackbar
        style={style}
        open={open}
        onClose={handleClose}
        message={t(
          'passport:you_are_using_icapital_passport',
          'You are using iCapital Passport. You can now switch between accounts.'
        )}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        action={action}
      />
    </div>
  );
}

PassportSnackbar.defaultProps = {
  togglePassportMenu: () => {},
};

PassportSnackbar.propTypes = {
  togglePassportMenu: func,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      togglePassportMenu: actions.togglePassportMenu,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(PassportSnackbar);
