import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useMemo } from 'react';
import { shellUi } from '@icapitalnetwork/shell-ui';
import { connect } from 'react-redux';
// @ts-expect-error Custom loader via ?url not able to resolve to package.json entry but it exists
// eslint-disable-next-line import/no-unresolved
import loginMFE from '@icapitalnetwork/login/spa.js?url';
import { IcnSpinner } from '@icapitalnetwork/react-component-library';
import { Provider as AuthProvider } from '@icapitalnetwork/authentication';
import { routeCodes } from './router';
import { isHomepageMFEEnabled as getIsHomepageMFEEnabled } from './modules/landing_page/containers/unified_homepage/utils';
import * as icnBootstrapSelectors from './selectors/icnBootstrapSelectors';
import useRouteListeners from './hooks/useRouteListeners';
import useHeartBeat from './hooks/useHeartBeat';
const SITENAV_BLACKLIST = [
    '/icn_react/static/consent_form',
    '/icn_react/static/esign_hub',
    '/icn_react/static/onboarding',
    '/icn_react/static/select_accounts',
    '/icn_react/static/link_accounts',
    '/icn_react/static/pdf_viewer',
    '/icn_react/static/secure_document_transfer',
];
function ShellInner({ children, icnReactBootstrap }) {
    useHeartBeat(icnReactBootstrap);
    return children;
}
function Shell({ icnReactBootstrap, children, }) {
    useRouteListeners(icnReactBootstrap);
    const mfeCustomProps = useMemo(() => {
        return {
            routerMode: 'history',
            bootstrapData: {
                icn_bootstrap: icnReactBootstrap,
            },
            host: 'icn',
            auth: {
                issuer: icnReactBootstrap.okta.issuer,
                clientId: icnReactBootstrap.okta.clientId,
                // Ideally it should be /login but some client integrations depend on controller logic from /
                loginPathname: '/',
            },
            // eslint-disable-next-line react/no-unstable-nested-components
            Loader: () => _jsx(IcnSpinner, { isFetching: true }),
        };
    }, [icnReactBootstrap]);
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f;
        const isMultiAssetSiteNav = icnReactBootstrap.white_label_partner.enable_multi_asset_mfe;
        const isMultiAssetHomepage = (_c = (_b = (_a = icnReactBootstrap === null || icnReactBootstrap === void 0 ? void 0 : icnReactBootstrap.user) === null || _a === void 0 ? void 0 : _a.permissions) === null || _b === void 0 ? void 0 : _b.includes) === null || _c === void 0 ? void 0 : _c.call(_b, 'see_unified_homepage_multi_asset');
        const isHomepageMFEEnabled = getIsHomepageMFEEnabled(icnReactBootstrap);
        const isSiteNavMFEEnabled = icnReactBootstrap.white_label_partner.enable_sitenav_mfe && icnReactBootstrap.user;
        const canSeePlatformGuidesSlideout = (_f = (_e = (_d = icnReactBootstrap === null || icnReactBootstrap === void 0 ? void 0 : icnReactBootstrap.user) === null || _d === void 0 ? void 0 : _d.permissions) === null || _e === void 0 ? void 0 : _e.includes) === null || _f === void 0 ? void 0 : _f.call(_e, 'view_platform_guides_slide_out');
        shellUi({
            devMode: process.env.NODE_ENV !== 'production',
            customProps: mfeCustomProps,
            manifest: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, (icnReactBootstrap.white_label_partner.enable_new_login_page
                ? {
                    '@icapitalnetwork/login': {
                        entry: loginMFE,
                        activeWhen: '/login',
                        customProps: {
                            auth: {
                                // Enable initial /authorize Okta call only for the login MFE to handle SSO redirects
                                // also ignore E2E tests as they are exhausting the API quota per user very quickly
                                enableInitialAuthorize: icnReactBootstrap.env !== 'test',
                            },
                        },
                    },
                }
                : {})), (isSiteNavMFEEnabled
                ? {
                    'unified-sitenav': {
                        entry: '/apps/unified-sitenav/latest/spa.js',
                        activeWhen: ({ pathname }) => !SITENAV_BLACKLIST.some((exp) => new RegExp(exp).test(pathname)),
                        customProps: (name, location) => ({
                            isTranslucentNav: location.pathname.startsWith(`${routeCodes.SI_MFE}/home`) ||
                                (location.pathname.startsWith(routeCodes.HOME) && isHomepageMFEEnabled),
                            mode: isMultiAssetSiteNav ? ['icn', 'simon'] : ['icn'],
                            isFixedTop: location.pathname.startsWith('/icn_react/static/funds') ||
                                location.pathname.startsWith('/icn_react/static/research_funds') ||
                                location.pathname.startsWith('/icn_react/static/product_profile_builder/fund_profile'),
                        }),
                    },
                }
                : {})), (icnReactBootstrap.white_label_partner.enable_education_mfe || canSeePlatformGuidesSlideout
                ? {
                    'unified-education': {
                        entry: '/apps/unified-education/latest/spa.js',
                        activeWhen: '/icn_react/static/app/education',
                        customProps: {
                            isTempNavShowing: false,
                        },
                    },
                }
                : {})), (isHomepageMFEEnabled
                ? {
                    'unified-homepage': {
                        entry: '/apps/unified-homepage/latest/spa.js',
                        activeWhen: (location) => location.pathname.startsWith(routeCodes.HOME) && isHomepageMFEEnabled,
                        customProps: {
                            mode: isMultiAssetHomepage ? ['icn', 'simon'] : ['icn'],
                            basename: routeCodes.HOME,
                        },
                    },
                }
                : {})), (icnReactBootstrap.white_label_partner.enable_organization_management_mfe
                ? {
                    'unified-organization-management': {
                        entry: '/apps/unified-organization-management/latest/spa.js',
                        activeWhen: routeCodes.ORGANIZATION_MANAGEMENT,
                    },
                }
                : {})), (icnReactBootstrap.white_label_partner.enable_multi_asset_mfe
                ? {
                    '@simon/simonmarkets-web': {
                        entry: '/apps/@simon/simonmarkets-web/latest/spa.js',
                        activeWhen: routeCodes.SI_MFE,
                        customProps: {
                            showSitenav: false,
                            showFooter: false,
                            loadThomsonUtils: false,
                            showAlternatePageHeaderColor: true,
                        },
                    },
                }
                : {})), (icnReactBootstrap.white_label_partner.enable_sales_enablement_mfe
                ? {
                    'unified-sales-enablement': {
                        entry: '/apps/unified-sales-enablement/latest/spa.js',
                        activeWhen: routeCodes.SALES_ENABLEMENT,
                    },
                }
                : {})),
        });
    }, [icnReactBootstrap, mfeCustomProps]);
    return (_jsx(AuthProvider, { config: mfeCustomProps, children: _jsx(ShellInner, { icnReactBootstrap: icnReactBootstrap, children: children }) }));
}
export default connect((state) => ({
    icnReactBootstrap: icnBootstrapSelectors.getIcnReactBootstrap(state),
}))(Shell);
